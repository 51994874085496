@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');

.successCardContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.successCardHeading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 23.9845px;
    line-height: 36px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #384C5F;
}
.successCardDesc{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #384C5F;
}
.proccessCardDesc{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #384C5F;
    text-align: center;
}
.successCardDescContainer{
display: flex;
justify-content: center;
width: 80%;
}
.successCardImg{
    width: 250px;
}
.proccessCardImg{
    width: 200px;
    margin-top: 20px;
    margin-bottom: 20px;
}


.errorCardHeading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 23.9845px;
    line-height: 36px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #FD3C4F;
}

.errorCardDesc{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 30px;
    color: #384C5F;
    width: 75%;
}

.errorCardButton{
    background: #384C5F;
    border-radius: 11px;
    padding: 12px 81px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 27px;
    text-transform: uppercase;

    color: #FFFFFF;
    border: none;
    margin-bottom: 20px;
    width: 200px;
}