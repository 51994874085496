.imgMainContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #f2f2f2;
    padding: 30px 0px;
    min-height: 100vh;
}

.imgTag {
    width: 100%;
    height: auto;
}

.imgSubContainer{
    width: 375px;
    height: auto;
}



@media screen and (max-width: 390px) {
    .imgSubContainer{
        width: 95%;
    }
  } 