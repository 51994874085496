@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
*{
  outline: none;
}

.main{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100% !important;
    height: 380px !important;
    overflow-y: scroll !important;
}

.docMain{
  background: #F9FAFB;
    width: 45%;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
    margin-bottom: 20px;
}

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: '#f4f4f4',
  }

  .headerText{
    color: '#508DBC';
    font-size: 20;
    margin-bottom: 20;
    align-self: 'center'
  }
  .pdf{
    width:100%;
    height: 540
  }
  .button{
    align-items: 'center';
    background-color: '#508DBC';
    padding: 10;
    margin-left: 10;
    margin-right: 10;
  }
  .buttonText{
    color: '#DAFFFF',
  }
  .message{
    align-items: 'center';
    padding: 15;
    background-color: '#FFF88C',
  }

  .signCanvasContainer{
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: lightgray;
  }

  .DocPDF{
    /* width: 100% */
    margin-top: 390px;
  }
  .clientHeading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    text-transform: uppercase;
    color: #304E75;
    margin-top: 10px;
  }
  .sigCanvas{
    border-bottom: 1px solid rgba(48, 78, 117, 1);
    width: 90%;
    background-color: rgba(56, 76, 95, 0.02);
    height: 250px;
  }
  .signButton{
    background: #384C5F;
    border-radius: 6px;
    margin-bottom: 20px;
    margin-top: 10px;
    padding: 15px 81px;
    border:none;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Poppins';
  }
  .buttonContainers{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .editSignButton{
    border: 1px solid rgba(56, 76, 95, 0.08);
    border-radius: 8px;
    padding: 15px 0px;
    width: 45%;
    background: rgba(56, 76, 95, 0.03);
    text-align: center;
    text-transform: uppercase;
    color: #384C5F;
    font-weight: 500;
    font-size: 17px;
    font-family: 'Poppins';
  }

  .saveButton{
    background-color:#2f4050;
    border-radius: 8px;
    padding: 15px 0px;
    width: 45%;
    color: white;
    border:none;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
  }
  .cancelButton{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    
    text-align: center;
    text-transform: uppercase;
    color: #384C5F;
    margin-bottom: 20px;
    
  }
  .saveSignButton{
    width: 313px;
    height: 57px;

    background: #384C5F;
    border-radius: 8px;
    border: 1px solid rgba(144, 155, 165, 1);
    
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 20px;

  }

  .brandContainer{
    background-color: #2f4050;
    border-radius: 10px;
    padding: 10px;
    margin-top: 5px;
    width:min-content;
    color: white;
  }




.buttonContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.subscribeInactiveBtn{
  border-radius: 6px !important;
  padding: 10px 15px;
  font-weight: 600;
  border: none;
  background-color: lightgrey;
}
.subscribeActiveBtn{
  border-radius: 6px !important;
  padding: 10px 15px;
  font-weight: 600;
  background-color: #2db27c;
  border: none;
  color: white;

}
.subscribeActiveBtnCancel{
  border-radius: 6px !important;
  padding: 10px 15px;
  font-weight: 600;
  background-color: #CF142B;
  border: none;
  color: white;
}

ul{
  padding-inline-start:15px !important
}


.btnContainerSign{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 100px;
}
.errContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.img{
  height: 100px;
  width: 100px;
  margin-bottom: 20px;
}

.retry{
  border-radius: 6px !important;
  padding: 10px 20px;
  font-weight: 600;
  background-color: #2f4050;
  border: none;
  color: white;
  margin-top: 20px;
}

.clientNameHead{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #304E75;
}

.clientNameDesc{
  font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 36px;
text-transform: uppercase;
color: #304E75;
}
.clientNameContainer{
  margin-bottom: 10px;
  margin-top: 20px;
  width: 92%;
}
.mainLogo{
  width: 101px;
  height: 100px;
  margin-top: -50px;
}

.containerPagination{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color:  white;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 10px;
}

.pagec{
  color: #2f4050; 
  padding: 0px 7px;
}

.clearContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.clearDesc{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;

  color: #6C829D;

}

.clearImg{
  width: 24.11px;
  height: 24.11px;
}


@media screen and (max-width: 767px) {
  .docMain{
    width: 100%;
    margin-top: 40px !important;
  }
  .buttonContainers{
    flex-direction: column;
    width: 90%;
  }
  .editSignButton{
    width: 100%;
  }

  .saveButton{
    width: 100%;
    margin-top: 10px;
  }
  .DocPDF{
    margin-top: 90px !important;
  }
  .mainLogo{
    width: 80px;
    height: 80px;
    margin-top: -40px;
  }
  .clientNameContainer{
    margin-top: 10px;
  }
  .clientNameHead{
    font-size: 11px;
  }
  
.clientNameDesc{
font-size: 20px;
line-height: 36px;
}
}