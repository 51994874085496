.DocPDFs{
    position: relative !important;
}

.pageBackground{
    /* height:140vh !important; */
    width: max-content !important;
}

.cross{
    cursor: pointer;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    margin-top: -5px;
}

.pdf-container{
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
    width: max-content !important;
}
.containerPagination{
    display: flex;
    /* justify-content: center;
    align-items: center; */
    margin-top: 20px;
    margin-bottom: 10px;
    /* margin-left: 250px; */
}

.containerButton{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
}

.pagec{
    color: #2f4050; 
    padding: 0px 7px;
  }

  .imgContainer{
    border:1px solid #2f4050;
    border-radius: 3px;
  }

  .saveButton{
    background-color:#2f4050;
    border-radius: 8px;
    padding: 15px 0px;
    width: 45%;
    color: white;
    border:none;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    text-align: center;
    text-transform:uppercase;
    cursor: pointer
  }
.saveButtonInactive{
    background-color:grey;
    border-radius: 8px;
    padding: 15px 0px;
    width: 45%;
    color: white;
    border:none;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    text-align: center;
    text-transform:uppercase;
    margin: 0 !important;
}

.annotationLayer{
  height: 0% !important;
}
.textLayer{
  height: 0% !important;
}



  @media screen and (max-width: 767px) {
    .saveButton{
        width: 100%;
        margin-top: 10px;
      }
    .saveButtonInactive{
        width: 100%;
        margin-top: 10px;
      }
  }