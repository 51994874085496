@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');


.modal-header{
    border-bottom: none !important;
}

.brandCardBodyContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.brandCardBodyHeading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 36px;  
    color: #384C5F;
}

.brandCardBodyDesc{
    font-family: 'Poppins';
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 27px;

color: #384C5F;
margin-top: 10px;
}

.brandCardButton{
    background: #384C5F;
    border-radius: 6px;
    padding: 13px 70px;
    border: none !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 27px;
    text-transform: uppercase;

    color: #FFFFFF;
    margin-left:5px;
}
.allButton{
    background: #384C5F;
    border-radius: 6px;
    padding: 5px 20px;
    border: none !important;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 27px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-top: 20px;
    margin-bottom: -10px;
}
.brandCardButtonCancel{
    border: 1px solid rgba(56, 76, 95, 0.08);
    border-radius: 8px;
    padding: 13px 70px;
    background: rgba(56, 76, 95, 0.03);
    text-align: center;
    text-transform: uppercase;
    color: #384C5F;
    font-weight: 500;
    font-size: 17px;
    font-family: 'Poppins';
    margin-right: 5px;
}

.brandCardBodySubDesc{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    color: rgba(56, 76, 95, 0.5);
    text-align: center;
    margin-top: 10px;
}

.brandNameContainer{
    border: 2px solid #304E75;
    background-color: #F9FAFB;
    border-radius: 16px;
    width: 400px;
    height: 50px !important;
    margin-bottom: 10px;
    padding: 0px 15px !important;
    display: flex;
    align-items: center !important;
    justify-content: space-between;

}

.brandNameHeading{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 15px !important;
    line-height: 27px;
    color: #384C5F;
    margin-left: 10px;
}

.dot{
    height: 10px;
    width: 10px;
    border-radius: 10px;
    background-color: black;
    border-width: 1px;
}


.brandButtonContrainers{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
}
 
  .checkboxContainer{
    display: flex;
    align-items: center;
    margin-top: 30px;
    justify-content: center;
    width: 99%;
  }
  .checkDesc{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    color: rgba(56, 76, 95, 0.5);
    text-transform: uppercase;
    width: 400px;
    font-size: 12px;
    line-height: 20px;
  }
  .checkBtn{
    width:17px;
    height: 17px;
    margin-right: 5px;
    /* padding-top: 10px; */
  } 
  .checkBtnDot{
    width:17px;
  }

  .brandNameImg{
    width: 50px;
    height: 30px;
    object-fit: contain;
  }


/* Media */

@media screen and (max-width: 767px) {
    .brandCardBodyContainer{
        display: flex;
        flex-direction: column;
        align-items:flex-start;
        padding:0px 10px;
    }

    .brandCardBodySubDesc{
        text-align: left;
    }
    .br{
        display: none;
    }
    .brandCardBodyDesc{
       
    font-weight: 500;
    font-size: 13px;
    line-height: 27px;
    }
    .brandCardBodySubDesc{
        font-size: 12px;
        line-height: 20px;
        margin-top: 2px;
    }
    .brandCardButton{
        padding: 13px 55px;
        font-size: 17px;
        line-height: 27px;
        margin-left:0px;
    }
    
    .brandCardButtonCancel{
        padding: 13px 55px;
        font-weight: 500;
        font-size: 17px;
        margin-right: 0px;
    }
    .brandButtonContrainers{
        display: flex;
        width: 100%;
        justify-content: space-between !important;
        margin-top: 25px;
    }
}

@media screen and (max-width: 400px) {
    .brandNameContainer{
       
        width: 355px;
        height: 60px;
        margin-bottom: 10px;
       
    }
    .brandCardButton{
        padding: 11px 45px;
        font-size: 15px;
        line-height: 27px;
        margin-left:0px;
    }
    
    .brandCardButtonCancel{
        padding: 11px 45px;
        font-weight: 500;
        font-size: 15px;
        margin-right: 0px;
    }
    .checkDesc{
        width: 360px;
        font-size: 13px;
      }
}
