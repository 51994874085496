.listContainer{
    padding: 8px 25px;
    background: #384C5F;
    border-radius: 11px;
    margin-right: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
}


.listContainerInactive{
    padding: 8px 25px;
    background: rgba(144, 155, 165, 1);
    border-radius: 11px;
    margin-right: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    /* identical to box height */

    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
}